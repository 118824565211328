import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(
    // private authService: AuthService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;
    console.log("route guard called for", url);
    return this.checkLogin(url);
  }

  private checkLogin(url: string): boolean {
    // check if the user is indeed logged in. if the token has expired, renew it
    if (isPlatformBrowser(this.platformId)) {
      let auth = localStorage.getItem("a");
      localStorage.setItem("redir", url);
      if (auth) {
        return true;
      }
    }

    this.router.navigate(['/auth']);
    return false

  }
}